<script lang="ts" setup>
const { path } = useRoute()
const title = path.split('/').pop()

const { data, error } = await useAsyncData(
  'blog',
  queryContent(`blog`).findOne,
)
</script>

<template>
  <main class="min-h-dvh bg-blue-100 dark:bg-black">
    <BlogHeader :title="data?.title" />

    <BaseImage
      v-if="data?.thumbnail"
      class="max-h-[20rem] overflow-clip object-center"
      provider="unsplash"
      :src="data.thumbnail"
      :alt-text="data.title"
    />

    <div class="relative flex">
      <article class="max-w-4xl px-2 xl:mx-20 2xl:mx-32 2xl:w-fit">
        <slot />
      </article>

      <BlogInfo class="sticky right-4 top-4 hidden h-fit max-w-md 2xl:flex" />
    </div>
  </main>
</template>
