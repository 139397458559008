<script setup lang="ts">
import type { ParsedContent } from '@nuxt/content'
import { onMounted, ref } from 'vue'
import { useEventListener } from '@vueuse/core'
import BaseHeading from '#/domains/base/components/BaseText/BaseHeading.vue'

const allHeadings = ref([])

const activeHeadingId = ref<string | null>(null)

const chapterHeading = ref<HTMLDataListElement[]>()

const activeHeadingIndex = computed(() => {
  const index = props.headings.findIndex((heading) => {
    return heading.props.id === activeHeadingId.value
  })
  return index
})

const activeHeadingTop = computed(() => {
  if (!allHeadings.value.filter(e => !!e).length) {
    return 0
  }
  else {
    return chapterHeading.value?.at(activeHeadingIndex.value)?.offsetTop
  }
})

const activeHeadingHeight = computed(() => {
  if (!allHeadings.value.filter(e => !!e).length) {
    return 0
  }
  else {
    return chapterHeading.value
      ?.at(activeHeadingIndex.value)
      ?.getBoundingClientRect().height
  }
})

interface Props {
  headings: ParsedContent[]
}

const props = defineProps<Props>()

function handleScroll() {
  const articleNode = document.querySelector('article')

  if (!articleNode) return

  const nodes = Array.from(
    articleNode.querySelectorAll('h1, h2, h3, h4, h5, h6'),
  )

  let closestHeading = null
  let closestHeadingDistance = null

  for (let i = 0; i < nodes.length; i++) {
    const headingElement = nodes[i]
    const distance = Math.abs(
      (headingElement as HTMLElement).getBoundingClientRect().top,
    )

    if (closestHeadingDistance === null || distance < closestHeadingDistance) {
      closestHeading = headingElement
      closestHeadingDistance = distance
    }
  }

  if (closestHeading) {
    activeHeadingId.value = closestHeading.id
    // Remove active class from all headings
    allHeadings.value.forEach((heading) => {
      if (heading) heading.classList.remove('active')
    })
    // Add active class to the closest heading
    closestHeading.classList.add('active')
  }
}

async function getElementsById(headings) {
  allHeadings.value = headings
    .map((heading) => {
      if (!heading) return
      return document.getElementById(heading.props.id)
    })
    .filter(e => !!e)
  activeHeadingId.value = allHeadings.value[0]?.id || null
}

async function handleScopedNavigation(id: string) {
  // deactivateScroll = true
  const node = document.getElementById(id)
  // if (!node) {
  //   deactivateScroll = false
  // }

  // Remove active class from all headings
  allHeadings.value.forEach((heading) => {
    if (heading) heading.classList.remove('active')
  })

  // Add active class to the clicked heading
  node?.classList.add('active')

  activeHeadingId.value = id

  new Promise((resolve) => {
    node?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })
    setTimeout(() => {
      resolve()
    }, 1000)
  }).then(() => {
    // deactivateScroll = false
  })
}

useEventListener(window, 'scroll', handleScroll, {
  passive: true,
  capture: true,
})

onMounted(() => {
  getElementsById(props.headings)
  handleScroll()
})
</script>

<template>
  <BaseHeading
    tag="h3"
    class="mb-2 border-b border-white text-2xl font-bold"
  >
    📘 Chapters
  </BaseHeading>

  <ul class="list">
    <li
      v-for="heading in headings"
      ref="chapterHeading"
      :key="heading._id"
      class="line-clamp-2 text-wrap text-xl"
      :class="[{ active: heading.props.id === activeHeadingId }]"
      @click="handleScopedNavigation(heading.props.id)"
    >
      {{ heading.children[0].value }}
    </li>

    <li
      class="moving-div"
      :style="{
        top: `${activeHeadingTop}px`,
        height: `${activeHeadingHeight}px`,
      }"
    />
  </ul>
</template>

<style scoped lang="scss">
.chapters {
  @apply text-lg;
}

.list {
  @apply text-lg;
  position: relative;

  li {
    margin-left: 1rem;
    margin-bottom: 1rem;
    cursor: pointer;
    @apply text-wrap dark:text-neutral-300;
  }

  .active {
    @apply text-primary-950 dark:text-primary-50;
    border-radius: 1rem;
    cursor: text;
  }
}

.moving-div {
  @apply rounded-md bg-primary-950 dark:bg-primary-50;
  position: absolute;
  left: -2rem;
  width: 4px;
  height: 30px;
  transition:
    top 300ms ease,
    height 300ms ease;
}
</style>
