<script setup lang="ts">
import { watchEffect, watch, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useBreakpoints, useDateFormat, useEventListener } from '@vueuse/core'
import type { MarkdownNode } from '@nuxt/content'

const route = useRoute()
const breakpoints = useBreakpoints({ tablet: 800 })

interface State {
  headings: MarkdownNode[]
  publishedAt: string
  isCollapsed: boolean
}

const state = ref<State>({
  headings: [],
  publishedAt: '',
  isCollapsed: false,
})

watchEffect(() => {
  if (breakpoints?.greaterOrEqual('tablet').value) {
    state.value.isCollapsed = false
  }
})

watch(
  breakpoints.greaterOrEqual('tablet'),
  (b) => {
    if (b) {
      state.value.isCollapsed = false
    }
  },
  { immediate: true },
)

watch(
  route,
  async ({ path }) => {
    if (path === '/') return
    const res = await queryContent().where({ _path: path }).findOne()
    const date = useDateFormat(res.date, 'DD.MM.YYYY hh:mm')
    state.value.publishedAt = date.value
    extractAllHeading(res.body?.children)
  },
  {
    immediate: true,
  },
)

function extractAllHeading(children: MarkdownNode[] | undefined) {
  if (!children) {
    return
  }
  state.value.headings = children.filter(child => child.tag.includes('h'))
}

function handleNavClick(e) {
  if (breakpoints.isGreater('tablet')) return
  const $menuItem = e.target.closest('.blog-info-wrapper')

  if (!$menuItem) {
    state.value.isCollapsed = true
  }
}

useEventListener(window, 'click', handleNavClick)
</script>

<template>
  <BaseWrapper
    tag="aside"
    class="max-w-md px-8 pt-12"
  >
    <div v-show="!state.isCollapsed">
      <BaseCard class="mb-2">
        <BaseText tag="span">
          📆
        </BaseText>
        <BaseText>{{ state.publishedAt }}</BaseText>
      </BaseCard>

      <BaseChapters :headings="state.headings" />
    </div>
  </BaseWrapper>
</template>
